import { defineNuxtPlugin } from '#app'
import { plugin, defaultConfig } from '@formkit/vue'
import { resetCount } from '@formkit/core'
import config from '/home/vsts/work/1/s/packages/admin-dashboard/formkit.config.ts'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:rendered', () => {
    resetCount()
  })
  nuxtApp.vueApp.use(plugin, defaultConfig(config))
})
