export const extendSession = () => {
  const cookie = exportUserSession();
  const config = useRuntimeConfig();
  return $fetch<{ success: boolean; data: { sessionDuration: number } }>(`/legacy/auth/extend-session`, {
    baseURL: config.public.legacyApiBase,
    headers: {
      Authorization: `Bearer ${cookie.value}`,
    },
    method: 'GET',
  });
};

export const logout = () => {
  const cookie = exportUserSession();
  const config = useRuntimeConfig();
  return $fetch<{ success: boolean; data: { sessionDuration: number } }>(`/legacy/auth/logout`, {
    baseURL: config.public.legacyApiBase,
    headers: {
      Authorization: `Bearer ${cookie.value}`,
    },
    method: 'POST',
  });
};
