export default defineNuxtRouteMiddleware(async (to) => {
  const useSession = useSessionStore();
  const config = useRuntimeConfig();
  const useUser = useUserStore();

  if (
    config.public.sessionTimeoutRedirectURL !== to.path &&
    to.path !== '/access-code' &&
    to.path !== '/generate-error' &&
    to.path !== '/pdf/summary' &&
    to.path !== '/documents/preview'
  ) {
    useUser.setMiddlewareLoading(true);
    const cookie = exportUserSession();
    if (cookie.value === undefined || cookie.value === null) {
      useSession.setRedirectPath(encodeURIComponent(to.fullPath));
      await useSession.timeout();
    }

    try {
      await $fetch(`${config.public.legacyApiBase}/legacy/auth/validate-token`, {
        ...globalFetchOptions(),
        method: 'get',
      });
      useUser.setMiddlewareLoading(false);
    } catch (error) {
      await useSession.timeout();
      return;
    }
  } else {
    useUser.setMiddlewareLoading(false);
  }
});
