export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:beforeMount', (vueApp) => {
    const config = useRuntimeConfig();

    const pagesToExclude = ['access-code-static-must-be-deleted'];
    if (
      config.public.environment === 'production' &&
      pagesToExclude.includes(vueApp.config.globalProperties.$route.name as string)
    ) {
      window.location.href = '/404';
    }
  });
});
