// @ts-ignore
import CountryFlag from 'vue3-country-flag-icon';
import 'vue3-country-flag-icon/dist/CountryFlag.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('country-flag', CountryFlag);
  nuxtApp.vueApp.component('VueDatePicker', VueDatePicker);
});
