// /plugins/persistedstate.client.ts

import { createNuxtPersistedState } from 'pinia-plugin-persistedstate/nuxt';
import { useCookie } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const date = new Date();
  date.setTime(date.getTime() + 60 * 60 * 1000);
  nuxtApp.$pinia.use(
    createNuxtPersistedState(useCookie, {
      cookieOptions: {
        expires: date,
        maxAge: 60 * 60,
      },
    })
  );
});
