import revive_payload_client_YTGlmQWWT5 from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/vsts/work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xKuw1km5sV from "/home/vsts/work/1/s/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/packages/admin-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iusAUtqzOR from "/home/vsts/work/1/s/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_r3pgF3B1Uq from "/home/vsts/work/1/s/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import formkitPlugin_pZqjah0RUG from "/home/vsts/work/1/s/packages/admin-dashboard/.nuxt/formkitPlugin.mjs";
import i18n_dOTnCc6TUQ from "/home/vsts/work/1/s/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_1_vue_error_KRDOyNfx7r from "/home/vsts/work/1/s/packages/admin-dashboard/plugins/0.1.vue-error.ts";
import _0_sentry_YD2SvzFSDA from "/home/vsts/work/1/s/packages/admin-dashboard/plugins/0.sentry.ts";
import _1_persistedstate_client_Xg7MPEkgNl from "/home/vsts/work/1/s/packages/admin-dashboard/plugins/1.persistedstate.client.ts";
import _2_vue_query_oNBxDDseR0 from "/home/vsts/work/1/s/packages/admin-dashboard/plugins/2.vue-query.ts";
import _3_flag_AebcqdbDuo from "/home/vsts/work/1/s/packages/admin-dashboard/plugins/3.flag.ts";
import _4_conditional_routing_Gdmbc80g6h from "/home/vsts/work/1/s/packages/admin-dashboard/plugins/4.conditional-routing.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_iusAUtqzOR,
  plugin_client_r3pgF3B1Uq,
  formkitPlugin_pZqjah0RUG,
  i18n_dOTnCc6TUQ,
  chunk_reload_client_SeG0EjL5Ec,
  _0_1_vue_error_KRDOyNfx7r,
  _0_sentry_YD2SvzFSDA,
  _1_persistedstate_client_Xg7MPEkgNl,
  _2_vue_query_oNBxDDseR0,
  _3_flag_AebcqdbDuo,
  _4_conditional_routing_Gdmbc80g6h
]