import { default as index1pkDHedukRMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/access-code/index.vue?macro=true";
import { default as static_45must_45be_45deletedgmIdCskqvCMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/access-code/static-must-be-deleted.vue?macro=true";
import { default as additional_45assistance_45types1lYq4bS1ZWMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/additional-assistance-types.vue?macro=true";
import { default as indexHUd7fSLejtMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/funding-source/index.vue?macro=true";
import { default as manager2RQFyHJB3Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/funding-source/manage.vue?macro=true";
import { default as index49AfbMcDrcMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/notification/index.vue?macro=true";
import { default as manage4PREAhvIIeMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/notification/manage.vue?macro=true";
import { default as requiredMNEcpO6yA5Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/required.vue?macro=true";
import { default as financial_45assistancemuH86AADD6Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/financial-assistance.vue?macro=true";
import { default as _91id_93niyZyVb9X6Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/food-pantry/[id].vue?macro=true";
import { default as indexo9G6fkHJBjMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/index.vue?macro=true";
import { default as editUvxmKN1Cb6Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/[id]/edit.vue?macro=true";
import { default as showykQnMuTFdmMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/[id]/show.vue?macro=true";
import { default as indexhLYVIjeGGaMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/index.vue?macro=true";
import { default as edittazVw9kcqDMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/[stepId]/edit.vue?macro=true";
import { default as household_45membersi9XVY42R4rMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/[stepId]/household-members.vue?macro=true";
import { default as printgejMXA8qiuMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/print.vue?macro=true";
import { default as showhOVbs8UlAlMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/show.vue?macro=true";
import { default as indexfF5QMaAlKsMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/index.vue?macro=true";
import { default as _91id_93R03amZKb1ZMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/[id].vue?macro=true";
import { default as availabilityKcSz11BHfgMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/availability.vue?macro=true";
import { default as calendarwsVdJhovYwMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/calendar.vue?macro=true";
import { default as indexfhfnioUvqRMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/index.vue?macro=true";
import { default as managecfXJpuHrQCMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/manage.vue?macro=true";
import { default as previewt5qgJSVh58Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/documents/preview.vue?macro=true";
import { default as territoryFSfgsHfxgNMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/dynamic-form/territory.vue?macro=true";
import { default as indexArm6Ch0tllMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/faqs/index.vue?macro=true";
import { default as indexN6VtyMMb2UMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/[id]/index.vue?macro=true";
import { default as print3FUVu6CKniMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/[id]/print.vue?macro=true";
import { default as createyIHUe3HrTcMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/create.vue?macro=true";
import { default as indexwKd1c6YPITMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/index.vue?macro=true";
import { default as indexyYFnJQl3o9Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/[id]/index.vue?macro=true";
import { default as print1oJ3LNouJUMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/[id]/print.vue?macro=true";
import { default as createYoBmBUjcUqMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/create.vue?macro=true";
import { default as indexb3dQ7iAgf2Meta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/index.vue?macro=true";
import { default as generate_45errorNBH7pbTH5SMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/generate-error.vue?macro=true";
import { default as indexLKTEEP98jpMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/index.vue?macro=true";
import { default as indexCdzWyd9wkTMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/oversight/index.vue?macro=true";
import { default as summaryPEzI3cJCkYMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/pdf/summary.vue?macro=true";
import { default as indexEoLLr2gXkQMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/reports/fund-transactions/index.vue?macro=true";
import { default as indexNYeuzWDHYdMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/schedule/index.vue?macro=true";
import { default as _91id_93G7V10orLwXMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/[id].vue?macro=true";
import { default as createEj0eghi3jrMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/create.vue?macro=true";
import { default as indexNzvjNDQ4gSMeta } from "/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/index.vue?macro=true";
export default [
  {
    name: index1pkDHedukRMeta?.name ?? "access-code",
    path: index1pkDHedukRMeta?.path ?? "/access-code",
    meta: index1pkDHedukRMeta || {},
    alias: index1pkDHedukRMeta?.alias || [],
    redirect: index1pkDHedukRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/access-code/index.vue").then(m => m.default || m)
  },
  {
    name: static_45must_45be_45deletedgmIdCskqvCMeta?.name ?? "access-code-static-must-be-deleted",
    path: static_45must_45be_45deletedgmIdCskqvCMeta?.path ?? "/access-code/static-must-be-deleted",
    meta: static_45must_45be_45deletedgmIdCskqvCMeta || {},
    alias: static_45must_45be_45deletedgmIdCskqvCMeta?.alias || [],
    redirect: static_45must_45be_45deletedgmIdCskqvCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/access-code/static-must-be-deleted.vue").then(m => m.default || m)
  },
  {
    name: additional_45assistance_45types1lYq4bS1ZWMeta?.name ?? "admin-additional-assistance-types",
    path: additional_45assistance_45types1lYq4bS1ZWMeta?.path ?? "/admin/additional-assistance-types",
    meta: additional_45assistance_45types1lYq4bS1ZWMeta || {},
    alias: additional_45assistance_45types1lYq4bS1ZWMeta?.alias || [],
    redirect: additional_45assistance_45types1lYq4bS1ZWMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/additional-assistance-types.vue").then(m => m.default || m)
  },
  {
    name: indexHUd7fSLejtMeta?.name ?? "admin-documents-funding-source",
    path: indexHUd7fSLejtMeta?.path ?? "/admin/documents/funding-source",
    meta: indexHUd7fSLejtMeta || {},
    alias: indexHUd7fSLejtMeta?.alias || [],
    redirect: indexHUd7fSLejtMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/funding-source/index.vue").then(m => m.default || m)
  },
  {
    name: manager2RQFyHJB3Meta?.name ?? "admin-documents-funding-source-manage",
    path: manager2RQFyHJB3Meta?.path ?? "/admin/documents/funding-source/manage",
    meta: manager2RQFyHJB3Meta || {},
    alias: manager2RQFyHJB3Meta?.alias || [],
    redirect: manager2RQFyHJB3Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/funding-source/manage.vue").then(m => m.default || m)
  },
  {
    name: index49AfbMcDrcMeta?.name ?? "admin-documents-notification",
    path: index49AfbMcDrcMeta?.path ?? "/admin/documents/notification",
    meta: index49AfbMcDrcMeta || {},
    alias: index49AfbMcDrcMeta?.alias || [],
    redirect: index49AfbMcDrcMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/notification/index.vue").then(m => m.default || m)
  },
  {
    name: manage4PREAhvIIeMeta?.name ?? "admin-documents-notification-manage",
    path: manage4PREAhvIIeMeta?.path ?? "/admin/documents/notification/manage",
    meta: manage4PREAhvIIeMeta || {},
    alias: manage4PREAhvIIeMeta?.alias || [],
    redirect: manage4PREAhvIIeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/notification/manage.vue").then(m => m.default || m)
  },
  {
    name: requiredMNEcpO6yA5Meta?.name ?? "admin-documents-required",
    path: requiredMNEcpO6yA5Meta?.path ?? "/admin/documents/required",
    meta: requiredMNEcpO6yA5Meta || {},
    alias: requiredMNEcpO6yA5Meta?.alias || [],
    redirect: requiredMNEcpO6yA5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/documents/required.vue").then(m => m.default || m)
  },
  {
    name: financial_45assistancemuH86AADD6Meta?.name ?? "admin-financial-assistance",
    path: financial_45assistancemuH86AADD6Meta?.path ?? "/admin/financial-assistance",
    meta: financial_45assistancemuH86AADD6Meta || {},
    alias: financial_45assistancemuH86AADD6Meta?.alias || [],
    redirect: financial_45assistancemuH86AADD6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/financial-assistance.vue").then(m => m.default || m)
  },
  {
    name: _91id_93niyZyVb9X6Meta?.name ?? "admin-food-assistance-food-pantry-id",
    path: _91id_93niyZyVb9X6Meta?.path ?? "/admin/food-assistance/food-pantry/:id()",
    meta: _91id_93niyZyVb9X6Meta || {},
    alias: _91id_93niyZyVb9X6Meta?.alias || [],
    redirect: _91id_93niyZyVb9X6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/food-pantry/[id].vue").then(m => m.default || m)
  },
  {
    name: indexo9G6fkHJBjMeta?.name ?? "admin-food-assistance",
    path: indexo9G6fkHJBjMeta?.path ?? "/admin/food-assistance",
    meta: indexo9G6fkHJBjMeta || {},
    alias: indexo9G6fkHJBjMeta?.alias || [],
    redirect: indexo9G6fkHJBjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/admin/food-assistance/index.vue").then(m => m.default || m)
  },
  {
    name: editUvxmKN1Cb6Meta?.name ?? "applicants-id-edit",
    path: editUvxmKN1Cb6Meta?.path ?? "/applicants/:id()/edit",
    meta: editUvxmKN1Cb6Meta || {},
    alias: editUvxmKN1Cb6Meta?.alias || [],
    redirect: editUvxmKN1Cb6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: showykQnMuTFdmMeta?.name ?? "applicants-id-show",
    path: showykQnMuTFdmMeta?.path ?? "/applicants/:id()/show",
    meta: showykQnMuTFdmMeta || {},
    alias: showykQnMuTFdmMeta?.alias || [],
    redirect: showykQnMuTFdmMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/[id]/show.vue").then(m => m.default || m)
  },
  {
    name: indexhLYVIjeGGaMeta?.name ?? "applicants",
    path: indexhLYVIjeGGaMeta?.path ?? "/applicants",
    meta: indexhLYVIjeGGaMeta || {},
    alias: indexhLYVIjeGGaMeta?.alias || [],
    redirect: indexhLYVIjeGGaMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applicants/index.vue").then(m => m.default || m)
  },
  {
    name: edittazVw9kcqDMeta?.name ?? "applications-id-stepId-edit",
    path: edittazVw9kcqDMeta?.path ?? "/applications/:id()/:stepId()/edit",
    meta: edittazVw9kcqDMeta || {},
    alias: edittazVw9kcqDMeta?.alias || [],
    redirect: edittazVw9kcqDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/[stepId]/edit.vue").then(m => m.default || m)
  },
  {
    name: household_45membersi9XVY42R4rMeta?.name ?? "applications-id-stepId-household-members",
    path: household_45membersi9XVY42R4rMeta?.path ?? "/applications/:id()/:stepId()/household-members",
    meta: household_45membersi9XVY42R4rMeta || {},
    alias: household_45membersi9XVY42R4rMeta?.alias || [],
    redirect: household_45membersi9XVY42R4rMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/[stepId]/household-members.vue").then(m => m.default || m)
  },
  {
    name: printgejMXA8qiuMeta?.name ?? "applications-id-print",
    path: printgejMXA8qiuMeta?.path ?? "/applications/:id()/print",
    meta: printgejMXA8qiuMeta || {},
    alias: printgejMXA8qiuMeta?.alias || [],
    redirect: printgejMXA8qiuMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: showhOVbs8UlAlMeta?.name ?? "applications-id-show",
    path: showhOVbs8UlAlMeta?.path ?? "/applications/:id()/show",
    meta: showhOVbs8UlAlMeta || {},
    alias: showhOVbs8UlAlMeta?.alias || [],
    redirect: showhOVbs8UlAlMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/[id]/show.vue").then(m => m.default || m)
  },
  {
    name: indexfF5QMaAlKsMeta?.name ?? "applications",
    path: indexfF5QMaAlKsMeta?.path ?? "/applications",
    meta: indexfF5QMaAlKsMeta || {},
    alias: indexfF5QMaAlKsMeta?.alias || [],
    redirect: indexfF5QMaAlKsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/applications/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R03amZKb1ZMeta?.name ?? "appointments-id",
    path: _91id_93R03amZKb1ZMeta?.path ?? "/appointments/:id()",
    meta: _91id_93R03amZKb1ZMeta || {},
    alias: _91id_93R03amZKb1ZMeta?.alias || [],
    redirect: _91id_93R03amZKb1ZMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/[id].vue").then(m => m.default || m)
  },
  {
    name: availabilityKcSz11BHfgMeta?.name ?? "appointments-availability",
    path: availabilityKcSz11BHfgMeta?.path ?? "/appointments/availability",
    meta: availabilityKcSz11BHfgMeta || {},
    alias: availabilityKcSz11BHfgMeta?.alias || [],
    redirect: availabilityKcSz11BHfgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/availability.vue").then(m => m.default || m)
  },
  {
    name: calendarwsVdJhovYwMeta?.name ?? "appointments-calendar",
    path: calendarwsVdJhovYwMeta?.path ?? "/appointments/calendar",
    meta: calendarwsVdJhovYwMeta || {},
    alias: calendarwsVdJhovYwMeta?.alias || [],
    redirect: calendarwsVdJhovYwMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/calendar.vue").then(m => m.default || m)
  },
  {
    name: indexfhfnioUvqRMeta?.name ?? "appointments",
    path: indexfhfnioUvqRMeta?.path ?? "/appointments",
    meta: indexfhfnioUvqRMeta || {},
    alias: indexfhfnioUvqRMeta?.alias || [],
    redirect: indexfhfnioUvqRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/index.vue").then(m => m.default || m)
  },
  {
    name: managecfXJpuHrQCMeta?.name ?? "appointments-manage",
    path: managecfXJpuHrQCMeta?.path ?? "/appointments/manage",
    meta: managecfXJpuHrQCMeta || {},
    alias: managecfXJpuHrQCMeta?.alias || [],
    redirect: managecfXJpuHrQCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/appointments/manage.vue").then(m => m.default || m)
  },
  {
    name: previewt5qgJSVh58Meta?.name ?? "documents-preview",
    path: previewt5qgJSVh58Meta?.path ?? "/documents/preview",
    meta: previewt5qgJSVh58Meta || {},
    alias: previewt5qgJSVh58Meta?.alias || [],
    redirect: previewt5qgJSVh58Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/documents/preview.vue").then(m => m.default || m)
  },
  {
    name: territoryFSfgsHfxgNMeta?.name ?? "dynamic-form-territory",
    path: territoryFSfgsHfxgNMeta?.path ?? "/dynamic-form/territory",
    meta: territoryFSfgsHfxgNMeta || {},
    alias: territoryFSfgsHfxgNMeta?.alias || [],
    redirect: territoryFSfgsHfxgNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/dynamic-form/territory.vue").then(m => m.default || m)
  },
  {
    name: indexArm6Ch0tllMeta?.name ?? "faqs",
    path: indexArm6Ch0tllMeta?.path ?? "/faqs",
    meta: indexArm6Ch0tllMeta || {},
    alias: indexArm6Ch0tllMeta?.alias || [],
    redirect: indexArm6Ch0tllMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6VtyMMb2UMeta?.name ?? "funding-sources-id",
    path: indexN6VtyMMb2UMeta?.path ?? "/funding-sources/:id()",
    meta: indexN6VtyMMb2UMeta || {},
    alias: indexN6VtyMMb2UMeta?.alias || [],
    redirect: indexN6VtyMMb2UMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: print3FUVu6CKniMeta?.name ?? "funding-sources-id-print",
    path: print3FUVu6CKniMeta?.path ?? "/funding-sources/:id()/print",
    meta: print3FUVu6CKniMeta || {},
    alias: print3FUVu6CKniMeta?.alias || [],
    redirect: print3FUVu6CKniMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: createyIHUe3HrTcMeta?.name ?? "funding-sources-create",
    path: createyIHUe3HrTcMeta?.path ?? "/funding-sources/create",
    meta: createyIHUe3HrTcMeta || {},
    alias: createyIHUe3HrTcMeta?.alias || [],
    redirect: createyIHUe3HrTcMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/create.vue").then(m => m.default || m)
  },
  {
    name: indexwKd1c6YPITMeta?.name ?? "funding-sources",
    path: indexwKd1c6YPITMeta?.path ?? "/funding-sources",
    meta: indexwKd1c6YPITMeta || {},
    alias: indexwKd1c6YPITMeta?.alias || [],
    redirect: indexwKd1c6YPITMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funding-sources/index.vue").then(m => m.default || m)
  },
  {
    name: indexyYFnJQl3o9Meta?.name ?? "funds-id",
    path: indexyYFnJQl3o9Meta?.path ?? "/funds/:id()",
    meta: indexyYFnJQl3o9Meta || {},
    alias: indexyYFnJQl3o9Meta?.alias || [],
    redirect: indexyYFnJQl3o9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: print1oJ3LNouJUMeta?.name ?? "funds-id-print",
    path: print1oJ3LNouJUMeta?.path ?? "/funds/:id()/print",
    meta: print1oJ3LNouJUMeta || {},
    alias: print1oJ3LNouJUMeta?.alias || [],
    redirect: print1oJ3LNouJUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: createYoBmBUjcUqMeta?.name ?? "funds-create",
    path: createYoBmBUjcUqMeta?.path ?? "/funds/create",
    meta: createYoBmBUjcUqMeta || {},
    alias: createYoBmBUjcUqMeta?.alias || [],
    redirect: createYoBmBUjcUqMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/create.vue").then(m => m.default || m)
  },
  {
    name: indexb3dQ7iAgf2Meta?.name ?? "funds",
    path: indexb3dQ7iAgf2Meta?.path ?? "/funds",
    meta: indexb3dQ7iAgf2Meta || {},
    alias: indexb3dQ7iAgf2Meta?.alias || [],
    redirect: indexb3dQ7iAgf2Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: generate_45errorNBH7pbTH5SMeta?.name ?? "generate-error",
    path: generate_45errorNBH7pbTH5SMeta?.path ?? "/generate-error",
    meta: generate_45errorNBH7pbTH5SMeta || {},
    alias: generate_45errorNBH7pbTH5SMeta?.alias || [],
    redirect: generate_45errorNBH7pbTH5SMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/generate-error.vue").then(m => m.default || m)
  },
  {
    name: indexLKTEEP98jpMeta?.name ?? "index",
    path: indexLKTEEP98jpMeta?.path ?? "/",
    meta: indexLKTEEP98jpMeta || {},
    alias: indexLKTEEP98jpMeta?.alias || [],
    redirect: indexLKTEEP98jpMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexCdzWyd9wkTMeta?.name ?? "oversight",
    path: indexCdzWyd9wkTMeta?.path ?? "/oversight",
    meta: indexCdzWyd9wkTMeta || {},
    alias: indexCdzWyd9wkTMeta?.alias || [],
    redirect: indexCdzWyd9wkTMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/oversight/index.vue").then(m => m.default || m)
  },
  {
    name: summaryPEzI3cJCkYMeta?.name ?? "pdf-summary",
    path: summaryPEzI3cJCkYMeta?.path ?? "/pdf/summary",
    meta: summaryPEzI3cJCkYMeta || {},
    alias: summaryPEzI3cJCkYMeta?.alias || [],
    redirect: summaryPEzI3cJCkYMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/pdf/summary.vue").then(m => m.default || m)
  },
  {
    name: indexEoLLr2gXkQMeta?.name ?? "reports-fund-transactions",
    path: indexEoLLr2gXkQMeta?.path ?? "/reports/fund-transactions",
    meta: indexEoLLr2gXkQMeta || {},
    alias: indexEoLLr2gXkQMeta?.alias || [],
    redirect: indexEoLLr2gXkQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/reports/fund-transactions/index.vue").then(m => m.default || m)
  },
  {
    name: indexNYeuzWDHYdMeta?.name ?? "schedule",
    path: indexNYeuzWDHYdMeta?.path ?? "/schedule",
    meta: indexNYeuzWDHYdMeta || {},
    alias: indexNYeuzWDHYdMeta?.alias || [],
    redirect: indexNYeuzWDHYdMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93G7V10orLwXMeta?.name ?? "service-areas-id",
    path: _91id_93G7V10orLwXMeta?.path ?? "/service-areas/:id()",
    meta: _91id_93G7V10orLwXMeta || {},
    alias: _91id_93G7V10orLwXMeta?.alias || [],
    redirect: _91id_93G7V10orLwXMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/[id].vue").then(m => m.default || m)
  },
  {
    name: createEj0eghi3jrMeta?.name ?? "service-areas-create",
    path: createEj0eghi3jrMeta?.path ?? "/service-areas/create",
    meta: createEj0eghi3jrMeta || {},
    alias: createEj0eghi3jrMeta?.alias || [],
    redirect: createEj0eghi3jrMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/create.vue").then(m => m.default || m)
  },
  {
    name: indexNzvjNDQ4gSMeta?.name ?? "service-areas",
    path: indexNzvjNDQ4gSMeta?.path ?? "/service-areas",
    meta: indexNzvjNDQ4gSMeta || {},
    alias: indexNzvjNDQ4gSMeta?.alias || [],
    redirect: indexNzvjNDQ4gSMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/admin-dashboard/pages/service-areas/index.vue").then(m => m.default || m)
  }
]