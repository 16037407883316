// Create some re-useable definitions because
// many input types are identical in how
// we want to style them.
const textClassification = {
  label: '$reset block mb-1 proxima font-bold text-[20px] formkit-invalid:text-red-500',
  inner: `$reset flex items-center relative max-w-full rounded-md shadow-sm border border-gray-300 focus-within:border-red-1`,
  input:
    '$reset ring-0 focus:ring-0 shadow-none focus:shadow-none focus:outline-none border-none focus:border-none  block w-full sm:text-sm rounded-md py-3.5 disabled:bg-gray-300 placeholder-gray-300 placeholder:italic',
  messages: '$reset text-red-1 text-sm',
  suffixIcon: 'hover:text-blue-500',
};
const selectClassification = {
  label: '$reset block mb-1 proxima font-bold text-[20px] formkit-invalid:text-red-500',
  input:
    '$reset ring-0 focus:ring-0 shadow-none focus:shadow-none focus:outline-none border-none focus:border-none  block w-full sm:text-sm rounded-md py-3.5 disabled:bg-gray-300',
  messages: '$reset text-red-1 text-sm',
  suffixIcon: 'hover:text-blue-500',
};
const boxClassification = {
  fieldset: '$reset max-w-md border-0 rounded-md px-0 sm:px-2 py-2',
  legend: '$reset block mb-1 proxima font-bold text-[20px]  formkit-invalid:text-red-500',
  wrapper: '$reset space-y-4 sm:flex sm:items-center sm:space-y-0 ml-2 mr-3',
  help: '$reset mb-2',
  input: '$reset focus:ring-red-1 h-6 w-6 text-red-1 border-gray-300',
  label: '$reset block mb-1 proxima text-[16px] text-gray-500 ml-2 ',
  messages: '$reset text-red-1 text-sm',
  option: '!m-0',
};
const buttonClassification = {
  wrapper: '$reset mb-1 hidden',
  input: '$reset bg-blue-500 hover:bg-blue-700 text-white text-sm font-normal py-3 px-5 rounded',
};
const maskClassification = {
  label: '$reset block mb-1 proxima font-bold text-[20px] formkit-invalid:text-red-500',
  inner: `$reset flex items-center relative max-w-md rounded-md shadow-sm border border-gray-300 focus-within:border-red-1`,
  input:
    '$reset ring-0 focus:ring-0 shadow-none focus:shadow-none focus:outline-none border-none focus:border-none  block w-full sm:text-sm rounded-md py-3.5 disabled:bg-gray-300 p-3  placeholder-gray-300 placeholder:italic',
  messages: '$reset text-red-1 text-sm',
  suffixIcon: 'hover:text-blue-500',
};

// export our definitions using our above
// templates and declare one-offs and
// overrides as needed.
export default {
  // the global key will apply to all inputs
  button: buttonClassification,
  color: {
    label: 'block mb-1 font-bold text-sm',
    input: 'w-16 h-8 appearance-none cursor-pointer border border-gray-300 rounded-md mb-2 p-1',
  },
  date: textClassification,
  mask: maskClassification,
  'datetime-local': textClassification,
  checkbox: {
    ...boxClassification,
    option: 'mb-2',
    decorator: 'hidden',
    decoratorIcon: 'hidden',
  },
  email: textClassification,
  file: {
    label: 'block mb-1 font-bold text-sm',
    inner: 'max-w-md cursor-pointer',
    input:
      'text-gray-600 text-sm mb-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-blue-500 file:text-white hover:file:bg-blue-600',
    noFiles: 'block text-gray-800 text-sm mb-1',
    fileItem: 'block flex text-gray-800 text-sm mb-1',
    fileRemove: 'ml-auto text-blue-500 text-sm',
  },
  month: textClassification,
  number: textClassification,
  password: textClassification,
  radio: {
    ...boxClassification,
    options: 'flex items-center',
    decorator: 'hidden',
    decoratorIcon: 'hidden',
  },
  range: {
    inner: 'max-w-md',
    input:
      'form-range appearance-none w-full h-2 p-0 bg-gray-200 rounded-full focus:outline-none focus:ring-0 focus:shadow-none',
  },
  search: textClassification,
  select: {
    ...selectClassification,
    inner: `$reset flex items-center relative max-w-md rounded-md shadow-sm border border-gray-300 focus-within:border-red-1`,
    selectIcon: '!hidden',
  },
  dropdown: {
    ...selectClassification,
    inner: `$reset flex items-center relative max-w-md rounded-md shadow-sm border border-gray-300 focus-within:border-red-1`,
  },
  autocomplete: {
    label: '$reset block mb-1 proxima font-bold text-[20px] formkit-invalid:text-red-500',
  },
  submit: buttonClassification,
  tel: textClassification,
  text: textClassification,
  textarea: {
    ...textClassification,
    wrapper: '$reset max-w-full',
    input:
      '$reset shadow-sm border-gray-300 focus:ring-red-1 focus:border-red-1 block w-full sm:text-sm rounded-md py-3.5 disabled:bg-gray-300 block w-full h-32 px-3 text-base text-gray-700 placeholder-gray-400 focus:shadow-outline',
  },
  taglist: {
    label: '$reset block mb-1 proxima font-bold text-[20px] formkit-invalid:text-red-500',
  },
  time: textClassification,
  url: textClassification,
  week: textClassification,
};
