<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script lang="ts" setup>
import { useQueryClient } from '@tanstack/vue-query';
// const useErrorHandler = useErrorHandlerStore();
const route = useRoute();
const queryClient = useQueryClient();

// clear the error message ever time the user routes
// watch(
//   () => route.path,
//   () => {
//     useErrorHandler.errorMessage = '';
//   }
// );

// this helps us clear the vue-query cache inside the setup function
// watch(
//   () => useErrorHandler.shouldInvalidateQueries,
//   () => {
//     if (useErrorHandler.shouldInvalidateQueries) {
//       queryClient.invalidateQueries();
//       useErrorHandler.shouldInvalidateQueries = false;
//     }
//   }
// );
</script>
