import { useQuery } from '@tanstack/vue-query';
import { defineStore } from 'pinia';
import { EnumAssistanceRequestStatus, TsammPermission, hasAccess } from '~/composables/Enums';
import { useSessionStore } from '~/composables/stores/session';
import { ServiceAreaRolePermission, User } from '~/composables/types/User';

interface ResponseContext {
  response: {
    status: number;
  };
}

export const useUserStore = defineStore(
  'user',
  () => {
    const currentUser = ref<User>();
    const isAdminForLocation = ref(false);
    const canChat = ref(false);
    const canVideo = ref(false);
    const canAppointment = ref(false);
    const isSysAdmin = ref(false);
    const hasOversight = ref(false);
    const isMiddlewareLoading = ref(true);

    const setMiddlewareLoading = (value: boolean) => {
      isMiddlewareLoading.value = value;
    };

    const config = useRuntimeConfig();

    const getCurrentUser = async () => {
      //if (currentUser.value === undefined || currentUser.value === null) {
        const resp = await $fetch<{ data: User }>(`${config.public.legacyApiBase}/legacy/users/me`, {
          ...globalFetchOptions(),
          method: 'get',
          onResponseError(context: ResponseContext): Promise<void> | void {
            if (context.response.status === 401) {
              const useSession = useSessionStore();
              useSession.timeout();
            } else {
              const errorMessage = `Error.  Please try again or contact support.`;
              toastError('Error', errorMessage);
            }
          },
        });
        currentUser.value = resp.data;
      //}

      isAdminForLocation.value = getIsAdminForLocation();
      canChat.value = getCanChat();
      canVideo.value = getCanVideo();
      canAppointment.value = getCanAppointment();
      isSysAdmin.value = getIsSysAdmin();
      hasOversight.value = getHasOversight();
      return currentUser.value;
    };

    const clearUser = () => {
      currentUser.value = undefined;
      isAdminForLocation.value = false;
      canChat.value = false;
      canVideo.value = false;
      canAppointment.value = false;
    };

    // Gets user's SA Help Permissions
    const getUserPermissionTypes = () => {
      const permissions = ref(currentUser.value?.sahelpPermissions);
      const permissionTypes: number[] = [];
      if (permissions.value) {
        for (const item of permissions.value) {
          permissionTypes.push(item.permissionId);
        }
        return permissionTypes;
      }
    };

    const canEdit = (status?: EnumAssistanceRequestStatus, arStatus?: EnumAssistanceRequestStatus[], show = true) => {
      if (isAdminForLocation.value) return true;

      const userEditPermissions = getPermissionsForEditRole().filter((element) =>
        getUserPermissionTypes()?.includes(element)
      );

      if (userEditPermissions.length) return true;

      const paymentProcessingStatusAr = [
        EnumAssistanceRequestStatus.Approved,
        EnumAssistanceRequestStatus.ApprovedReceivedByFinance,
        EnumAssistanceRequestStatus.ApprovedFundingSent,
        EnumAssistanceRequestStatus.ApprovedClosed,
      ];

      const hasStatus = arStatus?.some((status) => paymentProcessingStatusAr.includes(status));

      const paymentProcessingStatusArEdit = [
        EnumAssistanceRequestStatus.Approved,
        EnumAssistanceRequestStatus.ApprovedReceivedByFinance,
      ];

      const hasStatusEdit = arStatus?.some((status) => paymentProcessingStatusArEdit.includes(status));

      if (status !== undefined && getUserPermissionTypes()?.includes(TsammPermission.SAHelpPaymentProcessing)) {
        if (show) return paymentProcessingStatusAr.includes(status) || hasStatus;
        else return paymentProcessingStatusArEdit.includes(status) || hasStatusEdit;
      }

      return false;
    };

    const canComment = () => {
      if (isAdminForLocation.value) return true;

      const userCommentPermissions = getPermissionsForCommentRole().filter((element) =>
        getUserPermissionTypes()?.includes(element)
      );
      return userCommentPermissions.length ? true : false;
    };

    const getCanChat = () => {
      if (isAdminForLocation.value) return true;

      const userChatPermissions = getPermissionsForChatRole().filter((element) =>
        getUserPermissionTypes()?.includes(element)
      );
      return userChatPermissions.length ? true : false;
    };

    const getCanVideo = () => {
      if (isAdminForLocation.value) return true;

      const userVideoPermissions = getPermissionsForVideoRole().filter((element) =>
        getUserPermissionTypes()?.includes(element)
      );
      return userVideoPermissions.length ? true : false;
    };

    const getCanAppointment = () => {
      if (isAdminForLocation.value) return true;

      const userAppointmentPermissions = getPermissionsForAppointmentRole().filter((element) =>
        getUserPermissionTypes()?.includes(element)
      );
      return userAppointmentPermissions.length ? true : false;
    };

    const canChangeStatus = (status: EnumAssistanceRequestStatus, serviceAreaId: string | undefined) => {
      if (currentUser.value) {
        return hasAccess(
          status,
          currentUser.value.sahelpPermissions.filter((p) => p.serviceAreaId === serviceAreaId).map((p) => p.permissionId)
        );
      }
      return false;
    };

    // This returns true if any of the user's permissions (legacy permissions, not SA Help permissions) have a userTypeId of CanManage (262) AND the permission locationId matches the user's locationId
    const getIsAdminForLocation = () => {
      if (!currentUser.value) {
        console.error('No current user');
        return false;
      }
      const licensedLocationId = currentUser.value.profile.licensedLocationId;
      return currentUser.value.permissions.some(
        (permission) =>
          permission.userTypeId === TsammPermission.CanManageSAHelp && permission.licensedLocationId === licensedLocationId
      );
    };

    const getUserPermission = (): number[] => {
      const p = currentUser.value?.permissions.map((_) => _.userTypeId) ?? [];
      const sp = currentUser.value?.sahelpPermissions.map((_) => _.permissionId) ?? [];
      return [...p, ...sp];
    };

    const isCaseManagerDirector = () => {
      const licensedLocationId = currentUser.value?.profile?.licensedLocationId ?? 0;
      return (
        currentUser.value?.permissions?.some(
          (permission) =>
            permission.userTypeId === TsammPermission.SAHelpCaseManagementDirector &&
            permission.licensedLocationId === licensedLocationId
        ) ||
        currentUser.value?.sahelpPermissions?.some(
          (permission) =>
            permission.permissionId === TsammPermission.SAHelpCaseManagementDirector &&
            permission.licensedLocationId === licensedLocationId
        )
      );
    };

    const isCaseManagerWorker = () => {
      const licensedLocationId = currentUser.value?.profile?.licensedLocationId ?? 0;
      return (
        currentUser.value?.permissions?.some(
          (permission) =>
            permission.userTypeId === TsammPermission.SAHelpCaseManagement &&
            permission.licensedLocationId === licensedLocationId
        ) ||
        currentUser.value?.sahelpPermissions?.some(
          (permission) =>
            permission.permissionId === TsammPermission.SAHelpCaseManagement &&
            permission.licensedLocationId === licensedLocationId
        )
      );
    };

    const getIsSysAdmin = (): boolean => {
      return (
        currentUser.value?.permissions?.some((permission) => permission.userTypeId === TsammPermission.DPISystemAdmin) ||
        false
      );
    };

    const getIsCanManage = (): boolean => {
      return (
        currentUser.value?.permissions?.some((permission) => permission.userTypeId === TsammPermission.CanManageSAHelp) ||
        false
      );
    };

    const getHasOversight = (): boolean => {
      return (
        currentUser.value?.permissions?.some((permission) => permission.userTypeId === TsammPermission.SAHelpOversight) ||
        false
      );
    };

    const hasAccessPermissionInServiceArea = (perms: number[], serviceAreaId: string) => {
      return currentUser.value?.sahelpPermissions?.some(
        (p: ServiceAreaRolePermission) => p.serviceAreaId === serviceAreaId && perms.includes(p.permissionId)
      );
    };

    const hasAccessToServiceArea = (serviceAreaId: string) => {
      return (
        currentUser.value?.sahelpPermissions?.some((p: ServiceAreaRolePermission) => p.serviceAreaId === serviceAreaId) ||
        isAdminForLocation.value
      );
    };

    const hasAnySAhelpPerms = () => {
      return currentUser.value?.sahelpPermissions?.length;
    };

    const getPermissionsForEditRole = () => {
      return [
        TsammPermission.SAHelpCaseManagement,
        TsammPermission.SAHelpCaseManagementDirector,
        TsammPermission.SAHelpSupport,
        TsammPermission.CanManageSAHelp,
      ];
    };

    const getPermissionsForCommentRole = () => {
      return [
        TsammPermission.SAHelpCaseManagement,
        TsammPermission.SAHelpCaseManagementDirector,
        TsammPermission.SAHelpSupport,
        TsammPermission.SAHelpPaymentProcessing,
        TsammPermission.CanManageSAHelp,
      ];
    };

    const getPermissionsForChatRole = () => {
      return [
        TsammPermission.SAHelpCaseManagement,
        TsammPermission.SAHelpCaseManagementDirector,
        TsammPermission.SAHelpSupport,
        TsammPermission.CanManageSAHelp,
      ];
    };

    const getPermissionsForVideoRole = () => {
      return [
        TsammPermission.SAHelpCaseManagement,
        TsammPermission.SAHelpCaseManagementDirector,
        TsammPermission.SAHelpSupport,
        TsammPermission.CanManageSAHelp,
      ];
    };

    const getPermissionsForAppointmentRole = () => {
      return [
        TsammPermission.SAHelpCaseManagement,
        TsammPermission.SAHelpCaseManagementDirector,
        TsammPermission.SAHelpSupport,
        TsammPermission.CanManageSAHelp,
      ];
    };

    const notifChecked = ref(false);
    const setNotifChecked = (val: boolean) => {
      notifChecked.value = val;
    };
    const checkLicensedLocationStatus = (options: any) => {
      notifChecked.value = true;
      const fetcher = async () =>
        $fetch<{ success: boolean; data: boolean }>(`/licensed-locations/status`, {
          ...globalFetchOptions(),
          method: 'get',
        });
      return useQuery({ queryKey: ['licensed-locations-status'], queryFn: fetcher, ...globalUseQueryOptions(), ...options });
    };

    return {
      currentUser,
      getCurrentUser,
      isAdminForLocation,
      isSysAdmin,
      hasOversight,
      hasAccessPermissionInServiceArea,
      hasAccessToServiceArea,
      hasAnySAhelpPerms,
      clearUser,
      getPermissionsForEditRole,
      getPermissionsForCommentRole,
      getPermissionsForChatRole,
      getPermissionsForVideoRole,
      getPermissionsForAppointmentRole,
      canEdit,
      canComment,
      canChat,
      canVideo,
      canAppointment,
      getUserPermissionTypes,
      isCaseManagerDirector,
      isCaseManagerWorker,
      canChangeStatus,
      isMiddlewareLoading,
      setMiddlewareLoading,
      getUserPermission,
      notifChecked,
      setNotifChecked,
      checkLicensedLocationStatus,
      getIsCanManage,
    };
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
);
